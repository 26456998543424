a {
  color: #66d9ef;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

body {
  color: #fff;
  background-color: #272822;
}

h1 {
  color: #66d9ef;
  font-family: helvetica,sans-serif;
  padding: 1rem;
}

h2 {
  color: #ffd866;
  font-family: helvetica,sans-serif;
}

header {
  display: flex;
  justify-content: center;
}

img { 
  border: solid #f92672 0.2rem;
  max-width: 100%;
}

p {
  font-family: Consolas, monaco, monospace;
}

select {
  background-color: #272822;
  border: solid #f92672;
  color: #f92672;
  cursor: pointer;
  font-size: 2rem;
  padding: 0.5rem;
}

select > option {
  color: #f92672;
}

ol {
  list-style-type: none;
  padding: 0;
}

.App {
  text-align: center;
  padding: 2rem 10%;
}

.green {
  background-color: green;
}

.movieDetails {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  padding-bottom: 3rem;
}

.movieDetails section {
  max-width: 50%;
  padding: 0 2rem;
}

.movieList {
  align-items: flex-end;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.movieList li {
  flex: 0 1 25%;
  padding: 1rem;
}


.nicCageModeInput {
  cursor: pointer;
  font-size: 1rem;
  margin-top: 1rem;
}

.nicCageModeInput input {
  background-color: #272822;
}

.nicCageModeInput label {
  margin-right: 1rem;
  font-family: 'Brush Script MT',cursive;
}

.ratingBadge {
  border-radius: 2rem;
  display: inline-block;
  font-size: 4rem;
  font-weight: bold;
  padding: 2rem;
}

.red {
  background-color: red;
}

.yellow {
  background-color: yellow;
  color: black;
}

@media (max-width: 800px) {
  .movieDetails section {
    max-width: 100%;
  }

  .movieList li {
    flex-basis: 100%;
  }
}
